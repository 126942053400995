@font-face {
  font-family: GoshaSans;
  src: url("GoshaSans-Ultralight.e7d8987b.otf");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: GoshaSans;
  src: url("GoshaSans-Light.e66c84eb.otf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: GoshaSans;
  src: url("GoshaSans-Regular.cd953aed.otf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Neue World;
  src: url("NeueWorld-SemiCondensedLight.09fab4d8.otf");
  font-weight: 500;
  font-style: normal;
}

* {
  font-display: block;
}

h1.navigation {
  color: #fff;
  text-transform: unset;
  font-variation-settings: "wght" 300, "wdth" 85;
  z-index: 1500;
  font-family: Neue World;
  font-size: 100px;
  font-weight: 500;
  line-height: 1;
}

@media screen and (max-width: 768px) {
  h1.navigation {
    font-size: 48px;
    line-height: 55px;
  }
}

@media screen and (max-width: 350px) {
  h1.navigation {
    font-size: 40px;
    line-height: 50px;
  }
}

h1.navigation a {
  width: max-content;
  display: block;
}

h1.navigation .new__menuLink {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 1030px) {
  h1.navigation .new__menuLink {
    max-width: calc(100vw - 124px);
    line-height: 38px;
  }
}

h1.navigation .new__menuLink.agency__name {
  right: 0;
  left: unset;
}

h1.navigation .new__menuLink .char, h1.navigation .new__menuLink.hide {
  opacity: 0;
}

h1.navigation.zBellow {
  z-index: 0 !important;
}

@media screen and (max-width: 1030px) {
  h1.navigation.zBellow {
    z-index: 1500 !important;
  }
}

h1.navigation.peNone {
  pointer-events: none;
}

@media screen and (max-width: 1030px) {
  h1.navigation.peNone {
    pointer-events: unset;
  }
}

.footer__link > .navigation > .new__menuLink {
  top: unset;
  bottom: 10px;
}

@media screen and (max-width: 1030px) {
  .footer__link > .navigation > .new__menuLink {
    max-width: calc(100vw - 77px);
  }
}

h1 .char {
  display: inline-block;
}

h1 .char:not(:first-child) {
  text-transform: unset;
}

h1:hover .char {
  animation-play-state: paused !important;
}

@keyframes breathe {
  0% {
    font-variation-settings: "wght" 300, "wdth" 85;
    font-size: 100px;
  }

  60% {
    font-variation-settings: "wght" 500, "wdth" 100;
    font-size: 120px;
  }

  100% {
    font-variation-settings: "wght" 300, "wdth" 85;
    font-size: 100px;
  }
}

h1.project {
  color: #fff;
  font-family: GoshaSans;
  font-size: 220px;
  font-weight: 200;
  line-height: 264px;
}

h1.project .char {
  text-transform: uppercase !important;
}

@media screen and (max-width: 768px) {
  h1.project {
    text-align: center;
    word-break: break-word;
    font-size: 80px;
    line-height: 85px;
  }
}

h4.link {
  color: #fff;
  text-transform: uppercase;
  width: 90px;
  height: 40px;
  font-family: GoshaSans;
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
}

@media screen and (max-width: 768px) {
  h4.link {
    font-size: 18px;
    line-height: 22px;
  }
}

h4.link a {
  display: block;
}

h4.link svg {
  stroke-dasharray: 280;
  stroke-dashoffset: 280px;
  stroke-width: 1px;
  transition: all .8s ease-out;
  position: absolute;
  top: -6px;
  left: -13px;
}

h4.link:hover svg {
  stroke-dasharray: 280;
  stroke-dashoffset: 0;
}

h4.link a.active svg {
  stroke-dasharray: 280 !important;
  stroke-dashoffset: 0 !important;
}

html.has-scroll-smooth {
  overflow: hidden;
}

html.has-scroll-dragging {
  user-select: none;
}

.has-scroll-smooth body {
  overflow: hidden;
}

.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh;
}

[data-scroll-direction="horizontal"] [data-scroll-container] {
  height: 100vh;
  white-space: nowrap;
  display: inline-block;
}

[data-scroll-direction="horizontal"] [data-scroll-section] {
  vertical-align: top;
  white-space: nowrap;
  height: 100%;
  display: inline-block;
}

.c-scrollbar {
  z-index: 99;
  width: calc(100% - 28px);
  height: 100%;
  transform-origin: 100%;
  background: #ffffff1a;
  margin: 14px;
  transition: transform .3s, opacity .3s;
  position: absolute;
  top: 0;
  right: 0;
}

.c-scrollbar:hover {
  transform: scaleX(1.45);
}

.c-scrollbar:hover, .has-scroll-scrolling .c-scrollbar, .has-scroll-dragging .c-scrollbar {
  opacity: 1;
}

[data-scroll-direction="horizontal"] .c-scrollbar {
  height: 1px;
  top: auto;
  bottom: 0;
  transform: scaleY(1);
}

[data-scroll-direction="horizontal"] .c-scrollbar:hover {
  transform: scaleY(1.3);
}

.c-scrollbar_thumb {
  opacity: .5;
  width: 7px;
  cursor: -webkit-grab;
  cursor: grab;
  background-color: #000;
  border-radius: 10px;
  position: absolute;
  top: 0;
  right: 0;
}

.has-scroll-dragging .c-scrollbar_thumb {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

[data-scroll-direction="horizontal"] .c-scrollbar_thumb {
  bottom: 0;
  right: auto;
}

* {
  box-sizing: border-box;
  margin: 0;
}

@media screen and (max-width: 769px) {
  body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

@media screen and (max-width: 769px) {
  html {
    height: -webkit-fill-available;
  }
}

::selection {
  color: #111;
  background: #ffd717;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

a {
  color: inherit;
  text-decoration: none;
}

a:active, a:hover {
  outline: 0;
}

b, strong {
  font-weight: bold;
}

button, input, optgroup, select, textarea {
  font: inherit;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
}

img {
  max-width: 100%;
  height: auto;
}

.p__relative {
  position: relative;
}

.d__block {
  display: block;
}

.d__flex {
  display: flex;
}

.d__iblock {
  display: inline-block;
}

.jc__center {
  justify-content: center;
}

.jc__between {
  justify-content: space-between;
}

.wrap {
  flex-wrap: wrap;
}

.ai__center {
  align-items: center;
}

.ai__top {
  align-items: flex-start;
}

.ai__bottom {
  align-items: flex-end;
}

.dg.ac {
  height: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 111 !important;
}

:root {
  --app-height: 100%;
}

* {
  cursor: none !important;
}

html {
  width: 100vw;
  height: 100%;
  overscroll-behavior-x: none;
  overflow-x: hidden;
  cursor: none !important;
}

body {
  perspective: 2000px;
  perspective-origin: 50% 0;
  height: 100%;
  min-height: 100vh;
  background: linear-gradient(#fff0bb 0%, #ffdeee 34.38%, #dfffec 66.67%, #c0ffff 100%), #121212;
  cursor: none !important;
}

body .c-scrollbar_thumb {
  background-color: #0000;
}

body .c-scrollbar_thumb:before, body .c-scrollbar_thumb:after {
  width: 100%;
  height: 20px;
  content: "";
  background-color: #0000;
  margin-top: -20px;
  position: absolute;
}

body .c-scrollbar_thumb:after {
  margin-top: 1px;
}

@media screen and (max-width: 1030px) {
  body {
    width: 100vw;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    perspective: unset;
    perspective-origin: unset;
    height: unset;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

body.dark {
  background: #202020;
}

body.dark .c-scrollbar_thumb {
  background-color: #fff;
  transition: background-color .3s;
  opacity: .4 !important;
}

body.dark .c-scrollbar_thumb:hover {
  opacity: .8 !important;
}

body .c-scrollbar_thumb {
  background-color: #fff;
  transition: background-color .3s;
  opacity: .4 !important;
}

body .c-scrollbar_thumb:hover {
  opacity: .8 !important;
}

.smooth-scroll {
  min-height: 100vh;
}

@media screen and (max-width: 1030px) {
  .smooth-scroll {
    width: 100vw;
  }
}

.fullscreen_mode .video_overlay, .fullscreen_mode .workDetails__videoBanner *, .fullscreen_mode .vp-center, .altfullscreen_mode .video__wrapper *, .altfullscreen_mode vp-center {
  cursor: default !important;
}

.black {
  width: 100vw;
  height: 100vh;
  z-index: 16000;
  background: #101010;
  position: absolute;
  top: 0;
  left: 0;
}

.introWrapper {
  width: 100vw;
  background: #202020;
  position: relative;
  overflow: hidden;
}

.introWrapper.siteIn {
  transform: translate3d(0, 0, 0) !important;
}

@media screen and (max-width: 1030px) {
  .introWrapper {
    height: 100%;
    transform: none !important;
  }
}

.introWrapper__mask {
  width: 100vw;
  height: 100%;
  z-index: 200000;
  background: linear-gradient(#fff0bb 0%, #ffdeee 34.38%, #dfffec 66.67%, #c0ffff 100%);
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(.1%, 0, 0);
}

.introWrapper__mask:before {
  content: "";
  width: 20px;
  height: 100%;
  position: absolute;
}

.introWrapper__mask--mobileLoader {
  display: none;
}

@media screen and (max-width: 1030px) {
  .introWrapper__mask {
    transform-origin: bottom;
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: flex;
    transform: translate3d(0, 0, 0);
  }

  .introWrapper__mask--mobileLoader {
    display: block;
  }

  .introWrapper__mask--mobileLoader svg {
    max-width: 70px;
  }
}

.loadingScreen {
  width: 100vw;
  height: 100vh;
  z-index: 1400;
  opacity: 0;
  visibility: hidden;
  background: #202020;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.loadingScreen__content--anim {
  max-width: 100px;
}

@media screen and (max-width: 1030px) {
  .loadingScreen {
    position: fixed;
  }
}

.sreenSaver {
  width: 100%;
  height: 100%;
  z-index: 1200;
  background: #202020;
  position: fixed;
  top: 0;
  left: 0;
}

.sreenSaver canvas {
  background: #202020;
}

.mobileLoader {
  display: none;
}

@media screen and (max-width: 1030px) {
  .mobileLoader {
    opacity: 0;
    visibility: hidden;
    width: 60px;
    height: 60px;
    z-index: 9000;
    display: block;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.custom__Cursor {
  pointer-events: none;
  z-index: 999999;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: -25px;
  left: -25px;
}

.custom__Cursor.cursor2 {
  display: none;
}

.custom__Cursor.cursor2.show {
  display: block;
}

.custom__Cursor svg {
  max-width: 50px;
}

@media screen and (max-width: 1030px) {
  .custom__Cursor {
    display: none;
  }
}

.custom__Cursor--trail {
  opacity: 0;
  color: #fff;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
  letter-spacing: .05em;
  font-family: GoshaSans;
  font-size: 10px;
  transition: all 5ms;
  display: none;
  position: fixed;
}

.custom__Cursor--trail > * {
  will-change: transform;
  display: inline-block;
}

.custom__Cursor--trail > :first-of-type {
  margin-left: 25px;
}

.video_sound {
  height: 16px;
  width: 70px;
  z-index: 5;
  background-image: url("sound-white.5721416b.svg");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: contain;
  padding-left: 20px;
  display: none;
  position: absolute;
  bottom: 40px;
  left: 200px;
}

@media screen and (max-width: 1030px) {
  .video_sound {
    bottom: 15px;
    left: 20px;
    display: none !important;
  }
}

.video_sound.muted {
  background-image: url("sound-mute-white.5c45aae9.svg");
}

.video_sound_line {
  height: 1px;
  width: 100%;
  background-color: #fff3;
  margin: 7px 0;
  position: relative;
}

.video_sound_control {
  height: 100%;
  width: 50%;
  background: #fff;
}

.video_overlay, .alt1_overlay, .alt2_overlay {
  width: 100%;
  height: 100%;
  z-index: 2;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.video_overlay .videoState__text, .video_overlay .alt1videoState__text, .video_overlay .alt2videoState__text, .alt1_overlay .videoState__text, .alt1_overlay .alt1videoState__text, .alt1_overlay .alt2videoState__text, .alt2_overlay .videoState__text, .alt2_overlay .alt1videoState__text, .alt2_overlay .alt2videoState__text {
  opacity: 0;
  visibility: hidden;
  text-transform: uppercase;
  color: #fff;
  font-family: GoshaSans;
  font-style: normal;
  font-weight: 200;
  transition: all .3s;
}

@media screen and (max-width: 1030px) {
  .video_overlay .videoState__text, .video_overlay .alt1videoState__text, .video_overlay .alt2videoState__text, .alt1_overlay .videoState__text, .alt1_overlay .alt1videoState__text, .alt1_overlay .alt2videoState__text, .alt2_overlay .videoState__text, .alt2_overlay .alt1videoState__text, .alt2_overlay .alt2videoState__text {
    opacity: 1;
    visibility: visible;
  }
}

.video_overlay .videoState__text, .alt1_overlay .videoState__text, .alt2_overlay .videoState__text {
  font-size: 160px;
  line-height: 192px;
}

.video_overlay .videoState__text.vedioMode, .alt1_overlay .videoState__text.vedioMode, .alt2_overlay .videoState__text.vedioMode {
  opacity: 0 !important;
}

@media screen and (max-width: 1030px) {
  .video_overlay .videoState__text, .alt1_overlay .videoState__text, .alt2_overlay .videoState__text {
    font-size: 40px;
    line-height: 50px;
  }
}

.video_overlay .alt1videoState__text, .video_overlay .alt2videoState__text, .alt1_overlay .alt1videoState__text, .alt1_overlay .alt2videoState__text, .alt2_overlay .alt1videoState__text, .alt2_overlay .alt2videoState__text {
  font-size: 60px;
  line-height: 72px;
}

@media screen and (max-width: 1030px) {
  .video_overlay .alt1videoState__text, .video_overlay .alt2videoState__text, .alt1_overlay .alt1videoState__text, .alt1_overlay .alt2videoState__text, .alt2_overlay .alt1videoState__text, .alt2_overlay .alt2videoState__text {
    font-size: 40px;
    line-height: 50px;
  }
}

.video_overlay:hover .videoState__text, .video_overlay:hover .alt1videoState__text, .video_overlay:hover .alt2videoState__text, .alt1_overlay:hover .videoState__text, .alt1_overlay:hover .alt1videoState__text, .alt1_overlay:hover .alt2videoState__text, .alt2_overlay:hover .videoState__text, .alt2_overlay:hover .alt1videoState__text, .alt2_overlay:hover .alt2videoState__text {
  opacity: 1;
  visibility: visible;
}

.audio-progress-wrap {
  height: 41px;
  z-index: 10;
  width: 360px;
  margin-left: -180px;
  padding: 30px;
  display: none;
  position: absolute;
  bottom: 20px;
  left: 50%;
}

@media screen and (max-width: 1030px) {
  .audio-progress-wrap {
    padding: 20px;
    bottom: 0;
    display: none !important;
  }
}

.audio-progress-wrap .audio-progress {
  width: 100%;
  height: 1px;
  background-color: #ffffff94;
  position: relative;
}

.audio-progress-wrap .audio-progress .draggable-point {
  z-index: 1;
  width: 10px;
  height: 10px;
  cursor: none;
  color: #000;
  opacity: 0;
  -o-transition: opacity .4s ease;
  background-color: #fff;
  border: 0;
  justify-content: center;
  align-items: center;
  margin-top: -5px;
  margin-left: -5px;
  padding: 0;
  font-size: 12px;
  font-weight: 900;
  transition: opacity .4s;
  display: flex;
  position: absolute;
  transform: rotate(45deg);
  top: 0 !important;
}

.audio-progress-wrap .audio-progress .bar {
  height: 100%;
  background-color: #fff;
}

.audio-progress-wrap:hover .draggable-point {
  opacity: 1;
}

.full_screen_btn, .full_screen_btn2, .full_screen_btn3 {
  color: #fff;
  z-index: 5;
  background: none;
  border: 0;
  font-family: GoshaSans;
  font-size: 12px;
  font-weight: 400;
  text-decoration: underline;
  display: none;
  position: absolute;
  bottom: 40px;
  right: 180px;
}

@media screen and (max-width: 1030px) {
  .full_screen_btn, .full_screen_btn2, .full_screen_btn3 {
    bottom: 15px;
    right: 20px;
    display: none !important;
  }
}

.video_started:hover .video_sound, .video_started:hover .full_screen_btn, .video_started:hover .full_screen_btn2, .video_started:hover .full_screen_btn3, .video_started:hover .audio-progress-wrap {
  display: block;
}

.alt1 .full_screen_btn2, .alt2 .full_screen_btn2, .alt1 .full_screen_btn3, .alt2 .full_screen_btn3 {
  bottom: 15px;
  right: 15px;
}

main {
  z-index: 1;
  position: relative;
}

@media screen and (max-width: 1030px) {
  .container {
    background: #202020;
  }
}

.dsMenu {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 1030px) {
  .dsMenu {
    display: none;
  }
}

.dsMenu .tl {
  z-index: 1500;
  position: absolute;
  top: 3vw;
  left: 3vw;
}

.dsMenu .br {
  z-index: 99;
  width: max-content;
  z-index: 1500;
  position: absolute;
  bottom: 3vw;
  right: 50px;
}

.dsMenu .tr {
  z-index: 1500;
  position: absolute;
  top: 3vw;
  left: calc(100vw - 115px);
}

.dsMenu .bl {
  z-index: 1500;
  position: absolute;
  bottom: 3vw;
  left: 3.5vw;
}

.dsMenu.snGradient {
  transition: background all .6s;
  background: linear-gradient(#fff0bb 0%, #ffdeee 34.38%, #dfffec 66.67%, #c0ffff 100%), #ad8282;
  -webkit-transition: background .6s;
  -moz-transition: background .6s;
}

.dsMenu.snGradient h1, .dsMenu.snGradient a {
  color: #202020;
  transition: all .3s;
}

.dsMenu.snGradient h4 svg rect {
  stroke: #202020;
}

.header {
  width: 100vw;
  z-index: 1500;
  -webkit-backface-visibility: hidden;
  background: linear-gradient(0deg, #20202000 0%, #202020 67.55%);
  padding: 30px 20px 40px;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

@media screen and (max-width: 1030px) {
  .header {
    display: block;
  }
}

.header__wrapper {
  align-items: flex-start;
  position: relative;
}

@media screen and (max-width: 1030px) {
  .header__wrapper {
    top: 3vw;
  }
}

.header.zBellow {
  z-index: 0;
}

.header.snGradient {
  background: none;
}

.header.snGradient h1, .header.snGradient h4 {
  color: #202020;
}

.header.snGradient h4 svg rect {
  stroke: #202020;
}

@media screen and (max-width: 1030px) {
  .header h4 {
    text-align: right;
  }

  .header h4 svg {
    top: -8px;
    left: 18px;
  }
}

.header .tl, .header .br {
  z-index: 1500;
  position: relative;
}

@media screen and (max-width: 350px) {
  .header .br {
    margin-bottom: -2px;
  }
}

.header .tr, .header .bl {
  z-index: 1500;
  position: relative;
}

.footer {
  width: 100vw;
  z-index: 1500;
  -webkit-backface-visibility: hidden;
  background: linear-gradient(#20202000 0%, #202020 72.09%);
  padding: 40px 20px 20px;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
}

.footer.snGradient {
  background: none;
}

.footer.snGradient h1, .footer.snGradient h4 {
  color: #202020;
}

.footer.snGradient h4 svg rect {
  stroke: #202020;
}

@media screen and (max-width: 1030px) {
  .footer {
    display: block;
  }
}

.footer.zBellow {
  z-index: 0;
}

.footer__wrapper {
  align-items: flex-end;
  position: relative;
}

.footer .br {
  z-index: 1500;
}

@media screen and (max-width: 1030px) {
  .footer .br {
    text-align: right;
    bottom: 35px;
  }
}

@media screen and (max-width: 768px) {
  .footer .br {
    transform: translateY(6px);
  }
}

.footer .bl {
  z-index: 1500;
  align-items: center;
  display: flex;
  position: relative;
}

@media screen and (max-width: 1030px) {
  .footer h4 {
    text-align: left;
  }

  .footer h4 svg {
    top: 0;
    left: -16px;
  }
}

@media screen and (max-width: 1030px) {
  .pageContent {
    flex-direction: column;
  }
}

.pageContent__menu {
  width: 100vw;
  height: 100vh;
  background: #202020;
  position: absolute;
  top: 0;
  left: 0;
}

.pageContent__menu.snGradient {
  transition: background all .6s;
  background: linear-gradient(#fff0bb 0%, #ffdeee 34.38%, #dfffec 66.67%, #c0ffff 100%), #ad8282;
  -webkit-transition: background .6s;
  -moz-transition: background .6s;
}

.pageContent__menu.snGradient h1, .pageContent__menu.snGradient a {
  color: #202020;
  transition: all .3s;
}

@media screen and (max-width: 1030px) {
  .pageContent__menu {
    width: 0;
    height: 0;
    z-index: 9000;
    position: sticky;
    top: 0;
    bottom: 0;
  }
}

.pageContent__projects {
  padding-left: 350px;
  padding-right: 200px;
  position: relative;
}

@media screen and (max-width: 1030px) {
  .pageContent__projects {
    width: 100%;
    flex-direction: column;
    padding: 0;
  }
}

.pageContent__projects--mobileAnim {
  width: 100%;
  height: 100%;
  z-index: -1;
  justify-content: center;
  align-items: center;
  display: none;
  position: absolute;
  top: 0;
  left: -15%;
}

@media screen and (max-width: 1030px) {
  .pageContent__projects--mobileAnim {
    display: block;
  }

  .pageContent__projects--mobileAnim svg {
    width: 120% !important;
    height: 120% !important;
  }
}

.pageContent__projects .canvasDraw {
  width: 100vw;
  height: 100vh;
  display: block;
  position: absolute;
}

.pageContent__projects canvas {
  height: 100%;
  z-index: 2;
  pointer-events: none;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.pageContent__projectWrapper {
  height: 100vh;
  z-index: 999;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}

@media screen and (max-width: 1030px) {
  .pageContent__projectWrapper {
    width: 100%;
    height: auto;
    min-height: 70vh;
    justify-content: center;
  }
}

@media screen and (max-width: 1030px) {
  .pageContent__projectWrapper:first-child {
    min-height: 100vh;
  }
}

.pageContent__projectWrapper .projectsListItem__inner {
  padding: 0 120px;
}

@media screen and (max-width: 1030px) {
  .pageContent__projectWrapper .projectsListItem__inner {
    padding: 0 20px;
  }
}

.pageContent__projectWrapper .projectsListItem figure {
  opacity: 0;
  visibility: hidden;
  width: 848px;
  width: 53rem;
  height: 1152px;
  height: 72rem;
  pointer-events: none;
  z-index: -10;
  z-index: 100000;
  transition: opacity .1s, visibility 0s .1s;
  position: absolute;
  top: 50%;
}

@media screen and (max-width: 1030px) {
  .pageContent__projectWrapper .projectsListItem figure {
    display: none;
  }
}

.pageContent__projectWrapper .projectsListItem a {
  display: block;
}

.pageContent__projectWrapper:last-child, .pageContent__projectWrapper:last-child .projectsListItem {
  padding-right: 0;
}

.pageContent__homeFooter {
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.pageContent__homeFooter .video__wrapper {
  position: relative;
}

.pageContent__homeFooter .video__wrapper .intro_us_lisk {
  pointer-events: none;
  z-index: 1;
  display: block;
  position: relative;
}

.pageContent__homeFooter .video__wrapper .intro_us_lisk:before {
  content: "";
  width: 100%;
  height: 100%;
  z-index: 11;
  background: radial-gradient(circle closest-side, #20202000 50%, #202020);
  position: absolute;
}

.pageContent__homeFooter video {
  height: 100vh;
  width: 100%;
  z-index: 1;
  position: relative;
  transform: rotate(.01deg);
}

.pageContent__homeFooter--anim {
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media screen and (max-width: 1030px) {
  .pageContent__homeFooter--anim {
    width: 100%;
    margin-left: 0;
  }
}

.pageContent__homeFooter svg, .pageContent__homeFooter img {
  max-width: 800px;
}

.workPreloader, .projectPreloader, .notFoundPreloader, .usPreloader {
  width: 100%;
  height: 100%;
  z-index: 9999990;
  background: #181818;
  justify-content: center;
  align-items: center;
  transition: all .3s;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.workPreloader.hide, .projectPreloader.hide, .notFoundPreloader.hide, .usPreloader.hide {
  opacity: 0;
  visibility: hidden;
}

.workPreloader .preloadAnim svg, .workPreloader .preloadAnim2 svg, .projectPreloader .preloadAnim svg, .projectPreloader .preloadAnim2 svg, .notFoundPreloader .preloadAnim svg, .notFoundPreloader .preloadAnim2 svg, .usPreloader .preloadAnim svg, .usPreloader .preloadAnim2 svg {
  max-width: 65px;
}

@media screen and (max-width: 1030px) {
  .workPreloader, .projectPreloader, .notFoundPreloader, .usPreloader {
    height: 100vh;
  }
}

.work {
  min-width: 100vw;
  height: 100vh;
  align-items: center;
  padding: 230px 50px 185px;
  display: flex;
  position: relative;
}

@media screen and (max-width: 1030px) {
  .work {
    height: auto;
    background: #202020;
    padding: 120px 20px 100px;
    display: block;
  }
}

.work__wrapper {
  width: 100%;
  height: 100%;
  align-items: center;
}

@media screen and (max-width: 1030px) {
  .work__wrapper {
    height: auto;
    display: block;
  }
}

.work__wrapper--introText, .work__wrapper--insText {
  position: absolute;
}

.work__wrapper--introText p, .work__wrapper--insText p {
  color: #fff;
  white-space: normal;
  font-family: Neue World;
  font-size: 30px;
  font-weight: normal;
  line-height: 36px;
}

@media screen and (max-height: 730px) {
  .work__wrapper--introText p, .work__wrapper--insText p {
    font-size: 24px;
    line-height: 28px;
  }
}

@media screen and (max-width: 1030px) {
  .work__wrapper--introText p, .work__wrapper--insText p {
    font-size: 24px;
    line-height: 28px;
  }
}

@media screen and (min-width: 1800px) {
  .work__wrapper--introText p, .work__wrapper--insText p {
    font-size: 46px;
    line-height: 53px;
  }
}

.work__wrapper--introText p span, .work__wrapper--insText p span {
  display: inline-block;
}

.work__wrapper--introText p span.word:not(:last-child), .work__wrapper--insText p span.word:not(:last-child) {
  margin-right: 6px;
}

.work__wrapper--introText {
  position: absolute;
  top: -75px;
  left: 0;
}

@media screen and (max-width: 1030px) {
  .work__wrapper--introText {
    top: unset;
    left: unset;
    width: 100%;
    grid-column: span 12;
    margin-bottom: 20px;
    position: relative;
  }
}

@media screen and (min-width: 1800px) {
  .work__wrapper--introText {
    top: -110px;
  }
}

.work__wrapper--insText {
  text-align: right;
  bottom: -100px;
  left: calc(100vw - 795px);
}

@media screen and (max-width: 1030px) {
  .work__wrapper--insText {
    text-align: left;
    width: 100%;
    grid-column: span 12;
    margin-bottom: 40px;
    position: relative;
    bottom: unset !important;
    left: unset !important;
  }
}

.work__wrapper--insText p {
  max-width: 690px;
  width: 100%;
}

@media screen and (max-height: 730px) {
  .work__wrapper--insText {
    bottom: -60px;
    left: calc(100vw - 660px);
  }

  .work__wrapper--insText p {
    max-width: 560px;
  }
}

@media screen and (min-width: 1800px) {
  .work__wrapper--insText {
    bottom: -135px;
    left: calc(100vw - 1120px);
  }

  .work__wrapper--insText p {
    max-width: 1020px;
    width: 100%;
  }
}

.work__list {
  width: 100%;
  padding: 0;
  list-style: none;
  position: relative;
  overflow: visible;
}

@media screen and (max-width: 1030px) {
  .work__list {
    grid-column-gap: 10px;
    grid-row-gap: 20px;
    grid-template-columns: repeat(12, 1fr);
    display: grid;
  }
}

.work__list--item {
  width: 100%;
  width: 250px;
  margin-bottom: 15px;
  display: block;
  position: relative;
}

.work__list--item--mask {
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0s linear .5s;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 1030px) {
  .work__list--item--mask {
    display: none;
  }
}

@media screen and (max-width: 1030px) {
  .work__list--item {
    width: 100%;
    grid-column: span 6;
  }
}

@media screen and (min-width: 1800px) {
  .work__list--item {
    width: 280px;
  }
}

.work__list--item:not(:last-child) {
  margin-right: 12px;
}

.work__list--item .item__video {
  width: 100%;
  height: 150px;
  background: #262626;
  position: relative;
}

@media screen and (max-width: 1030px) {
  .work__list--item .item__video {
    display: none;
  }
}

@media screen and (min-width: 1800px) {
  .work__list--item .item__video {
    height: 160px;
  }
}

.work__list--item .item__video video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(.001deg);
}

.work__list--item .item__mobileThumb {
  width: 100%;
  height: 110px;
  background: none;
  display: none;
}

@media screen and (max-width: 1030px) {
  .work__list--item .item__mobileThumb {
    display: block;
  }
}

.work__list--item .item__mobileThumb img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.work__list--item .item__title {
  width: max-content;
  margin-top: 6px;
  position: relative;
}

.work__list--item .item__title span {
  text-transform: capitalize;
  color: #fff;
  mix-blend-mode: difference;
  opacity: .2;
  font-family: GoshaSans;
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: 13px;
  transition: all .3s;
}

@media screen and (max-width: 1030px) {
  .work__list--item .item__title span {
    opacity: 1;
  }
}

.work__list--item .item__title svg {
  width: 180%;
  height: 28px;
  stroke-dasharray: 280;
  stroke-dashoffset: 280px;
  stroke-width: 1px;
  transition: all .8s ease-out;
  position: absolute;
  top: -4px;
  left: 66%;
  transform: translateX(-50%);
}

.work__list--item .item__title svg.active {
  stroke-dasharray: 280;
  stroke-dashoffset: 0;
}

.work__list--item .item__title svg.active rect {
  width: 88%;
  height: 26px;
}

@media screen and (max-width: 1030px) {
  .work__list--item .item__title svg {
    display: none;
  }
}

.work__list--item:hover .item__title span {
  opacity: 1;
}

.work__list--item:hover .work__list--item--mask {
  opacity: 0;
  visibility: hidden;
}

.workDetails {
  width: max-content;
  height: 100vh;
  align-items: center;
  padding: 95px 60px 95px 130px;
  position: relative;
}

@media screen and (max-width: 1030px) {
  .workDetails {
    width: 100vw;
    height: auto;
    padding: 120px 20px;
    display: block;
  }
}

.workDetails__wrapper {
  width: max-content;
}

@media screen and (max-width: 1030px) {
  .workDetails__wrapper {
    width: 100%;
    display: block;
  }
}

.workDetails__backbtn {
  margin-top: 20px;
  display: none;
}

@media screen and (max-width: 1030px) {
  .workDetails__backbtn {
    display: block;
  }
}

.workDetails__backbtn a {
  color: #fff;
  font-family: GoshaSans;
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
}

.workDetails__videoBanner {
  width: calc(100vw - 260px);
  z-index: 99;
  position: relative;
}

@media screen and (max-width: 1030px) {
  .workDetails__videoBanner {
    width: 100%;
    transform: none !important;
  }
}

.workDetails__videoBanner .videoInner {
  width: 100%;
  z-index: 9999;
  height: calc(100vh - 170px);
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (max-width: 1030px) {
  .workDetails__videoBanner .videoInner {
    height: auto;
    padding-top: 56.25%;
    top: 0;
    transform: none !important;
  }
}

.workDetails__videoBanner .videoInner__controls {
  width: 100%;
  max-height: 700px;
  min-height: 585px;
  height: 100%;
  position: relative;
}

.workDetails__videoBanner .videoInner__controls.vedioMode {
  opacity: 0;
}

@media screen and (min-height: 900px) and (min-width: 1600) {
  .workDetails__videoBanner .videoInner__controls {
    max-height: 800px;
  }
}

@media screen and (min-height: 1000px) and (min-width: 1800px) {
  .workDetails__videoBanner .videoInner__controls {
    max-height: 900px;
  }
}

@media screen and (min-height: 1200px) and (min-width: 1900px) {
  .workDetails__videoBanner .videoInner__controls {
    max-height: 1100px;
  }
}

@media screen and (min-height: 1400px) and (min-width: 2000px) {
  .workDetails__videoBanner .videoInner__controls {
    max-height: 1300px;
  }
}

@media screen and (max-height: 750px) {
  .workDetails__videoBanner .videoInner__controls {
    max-height: 550px;
  }
}

@media screen and (max-height: 750px) {
  .workDetails__videoBanner .videoInner__controls {
    min-height: auto;
    max-height: 550px;
  }
}

@media screen and (max-width: 1030px) {
  .workDetails__videoBanner .videoInner__controls {
    min-height: auto;
  }
}

@media screen and (max-width: 575px) {
  .workDetails__videoBanner .videoInner__controls {
    min-height: auto !important;
  }
}

.workDetails__videoBanner iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.workDetails--more {
  justify-content: center;
  align-items: center;
  display: flex;
}

@media screen and (max-width: 1030px) {
  .workDetails--more {
    display: none;
  }
}

.workDetails--more__btn {
  color: #fff;
  text-transform: uppercase;
  opacity: 0;
  pointer-events: none;
  background-color: #0000;
  border: none;
  outline: none;
  padding: 20px;
  font-family: GoshaSans;
  font-size: 12px;
  line-height: 14px;
  transition: all .5s;
  transform: translateX(-20px)rotate(90deg);
}

.workDetails--more__btn.show {
  opacity: 1;
  pointer-events: all;
  transform: translateX(0)rotate(90deg);
}

.workDetails__workInfo {
  width: max-content;
  color: #fff;
  flex-direction: column;
  justify-content: space-between;
  gap: 80px;
  margin-top: 10vh;
  margin-bottom: 10vh;
  margin-left: 0;
}

.workDetails__workInfo.break--1 {
  flex-direction: row;
}

.workDetails__workInfo.break--1 .workBio {
  margin-top: -1.1px;
}

.workDetails__workInfo.break--2 {
  margin-top: 20vh;
}

.workDetails__workInfo.break--2 .workDetails__workInfo--bottom {
  flex-flow: column wrap;
  display: flex;
}

.workDetails__workInfo.break--2 .workDetails__workInfo--bottom .workBio {
  height: 100%;
  flex-shrink: 0;
  margin-top: -1.1px;
  margin-right: 80px;
}

.workDetails__workInfo.break--2 .workDetails__workInfo--bottom .altInfo {
  margin-bottom: 15px;
}

.workDetails__workInfo.break--2 .workDetails__workInfo--bottom .altInfo .title {
  margin-top: 0;
}

@media screen and (max-width: 1400px) {
  .workDetails__workInfo {
    margin-left: 80px;
  }
}

@media screen and (min-height: 800px) {
  .workDetails__workInfo {
    flex-direction: column;
    gap: 40px;
    margin-left: 80px;
  }
}

@media screen and (max-width: 1030px) {
  .workDetails__workInfo {
    min-height: auto;
    margin-top: 60px;
    margin-left: 0;
  }
}

@media screen and (max-width: 575px) {
  .workDetails__workInfo {
    min-height: auto !important;
  }
}

.workDetails__workInfo span, .workDetails__workInfo p {
  font-family: GoshaSans;
  font-weight: 400;
}

.workDetails__workInfo--top .clientInfo, .workDetails__workInfo--top .agencyInfo {
  opacity: 0;
  transition: all .3s cubic-bezier(.075, .82, .165, 1);
  transform: translateX(15px);
}

.workDetails__workInfo--top .clientInfo.show, .workDetails__workInfo--top .agencyInfo.show {
  opacity: 1;
  transform: translateX(0);
}

.workDetails__workInfo--top .clientInfo__title, .workDetails__workInfo--top .agencyInfo__title {
  text-transform: uppercase;
}

.workDetails__workInfo--top .clientInfo span, .workDetails__workInfo--top .agencyInfo span {
  font-size: 12px;
  line-height: 14px;
}

@media screen and (max-width: 1030px) {
  .workDetails__workInfo--top .clientInfo, .workDetails__workInfo--top .agencyInfo {
    opacity: 1;
    transform: translateY(0);
  }
}

.workDetails__workInfo--top .agencyInfo {
  margin-top: 20px;
}

.workDetails__workInfo--bottom {
  opacity: 0;
  transition: all .5s;
  transform: translateX(20px);
}

@media screen and (max-width: 1030px) {
  .workDetails__workInfo--bottom {
    opacity: 1;
    margin-top: 30px;
    margin-bottom: 0;
    padding-bottom: 0;
    transform: translateY(0);
  }
}

.workDetails__workInfo--bottom.show {
  opacity: 1;
  transform: translateX(0);
}

.workDetails__workInfo--bottom .workBio__title {
  margin-bottom: 18px;
}

.workDetails__workInfo--bottom .workBio__title span {
  text-transform: capitalize;
  color: #fff;
  font-family: GoshaSans;
  font-size: 22px;
  font-weight: normal;
  line-height: 1;
}

.workDetails__workInfo--bottom .workBio__text {
  max-width: 235px;
  width: 100%;
  margin-bottom: 30px;
}

.workDetails__workInfo--bottom .workBio__text p {
  white-space: normal;
  text-transform: capitalize;
  color: #fff;
  font-size: 12px;
  line-height: 14px;
}

.workDetails__workInfo--bottom .workBio__text pre {
  text-transform: capitalize;
  color: #fff;
  font-family: GoshaSans;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.workDetails__workInfo--bottom .altInfo {
  display: none;
}

.workDetails__workInfo--bottom .altInfo .title {
  margin-top: 15px;
}

.workDetails__workInfo--bottom .altInfo span {
  text-transform: capitalize;
  color: #fff;
  font-size: 12px;
  line-height: 14px;
}

.workDetails__workInfo--bottom .altInfo.show {
  display: block;
}

.workDetails__altBanner {
  min-height: 585px;
  height: 94%;
  z-index: 99;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 200px;
  display: none;
  position: relative;
}

.workDetails__altBanner.showAlt {
  display: flex;
}

.workDetails__altBanner .alt2 {
  margin-top: 30px;
}

@media screen and (max-height: 1400px) {
  .workDetails__altBanner {
    min-height: 520px;
  }
}

@media screen and (max-width: 1030px) {
  .workDetails__altBanner {
    margin-top: 30px;
    margin-left: 0;
  }

  .workDetails__altBanner .alt2 {
    margin-top: 30px;
  }
}

@media screen and (max-width: 575px) {
  .workDetails__altBanner {
    height: 100%;
    min-height: auto !important;
  }
}

.workDetails__altBanner .singleBanner {
  width: 364px;
  opacity: 0;
  padding-bottom: 56.96%;
  transition: all .3s cubic-bezier(.075, .82, .165, 1);
  position: relative;
  transform: translateX(20px);
}

.workDetails__altBanner .singleBanner:not(:first-child) {
  margin-top: 35px;
}

.workDetails__altBanner .singleBanner.fullscreenon {
  opacity: 1;
  padding: 0;
  transition: none;
}

@media screen and (max-width: 1030px) {
  .workDetails__altBanner .singleBanner {
    width: 100%;
    opacity: 1;
    margin-top: 35px;
    transform: none;
  }
}

.workDetails__altBanner .singleBanner.show {
  opacity: 1;
  transform: translateX(0);
}

.workDetails__altBanner .singleBanner__video.vedioMode :not(iframe) {
  opacity: 0;
}

.workDetails__altBanner .singleBanner iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.workDetails__altBanner .singleBanner:not(:last-child) {
  margin-bottom: 35px;
}

.workDetails__altBanner .singleBanner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.aboutUs {
  min-width: 100vw;
  height: 100vh;
  color: #fff;
  padding: 95px 55px 95px 50px;
  position: relative;
}

@media screen and (max-width: 1030px) {
  .aboutUs {
    height: auto;
    padding: 50px 0 90px;
    display: block;
  }
}

.aboutUs__wrapper {
  width: 100%;
  position: relative;
}

@media screen and (max-width: 1030px) {
  .aboutUs__wrapper {
    padding: 0 20px;
    display: block;
  }
}

.aboutUs p, .aboutUs a {
  white-space: normal;
  mix-blend-mode: difference;
  font-family: GoshaSans;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  position: relative;
}

@media screen and (min-width: 1650px) {
  .aboutUs p, .aboutUs a {
    font-size: 13px;
    line-height: 16px;
  }
}

.aboutUs__left {
  width: auto;
  padding-top: 80px;
  padding-bottom: 50px;
}

.aboutUs__left--wrapper {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

@media screen and (max-width: 1030px) {
  .aboutUs__left {
    margin-bottom: 0;
    padding-top: 30px;
    padding-bottom: 0;
    display: block;
  }
}

.aboutUs__left--model {
  width: 750px;
  height: 100%;
  align-items: flex-end;
  margin-right: 50px;
  display: flex;
  position: relative;
}

@media screen and (min-width: 1650px) {
  .aboutUs__left--model {
    width: 1025px;
  }
}

@media screen and (min-width: 2150px) {
  .aboutUs__left--model {
    width: 60vw;
  }
}

@media screen and (max-width: 1030px) {
  .aboutUs__left--model {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-right: 0;
    display: block;
  }

  .aboutUs__left--model .webgl2 {
    display: none;
  }
}

.aboutUs__left--model .mobile__model {
  display: none;
}

@media screen and (max-width: 1030px) {
  .aboutUs__left--model .mobile__model {
    width: 100%;
    display: block;
  }
}

.aboutUs__left--model .mobile__model video {
  width: 100%;
}

.aboutUs__left--model .webGl__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-90px)translateX(0);
}

.aboutUs__left--model .webGl__wrapper canvas {
  position: absolute;
  top: -85px;
  left: -480px;
}

@media screen and (max-width: 1030px) {
  .aboutUs__left--model .webGl__wrapper canvas {
    display: none !important;
  }
}

@media screen and (min-width: 1800px) {
  .aboutUs__left--model .webGl__wrapper canvas {
    left: -580px;
  }
}

.aboutUs__left--content {
  width: 430px;
  z-index: 1;
  position: relative;
}

@media screen and (min-width: 1800px) {
  .aboutUs__left--content {
    width: 530px;
  }
}

@media screen and (max-width: 1030px) {
  .aboutUs__left--content {
    width: 100%;
    margin-top: 50px;
    margin-left: 0;
  }
}

.aboutUs__left--content p {
  font-family: Neue World;
  font-size: 32px;
  line-height: 37px;
}

@media screen and (max-width: 1340px) {
  .aboutUs__left--content p {
    max-width: 360px;
    font-size: 30px;
    line-height: 34px;
  }
}

@media screen and (max-width: 1030px) {
  .aboutUs__left--content p {
    font-size: 24px;
    line-height: 28px;
  }
}

@media screen and (min-width: 1800px) {
  .aboutUs__left--content p {
    font-size: 46px;
    line-height: 53px;
  }
}

.aboutUs__left--content p .char {
  display: inline-block;
}

.aboutUs__left--content p.content__text {
  margin-bottom: 35px;
}

.aboutUs__left--content p.content__serviceTitle {
  text-transform: uppercase;
  margin-bottom: 15px;
}

.aboutUs__right {
  width: 100vw;
  padding-left: 120px;
  display: flex;
}

@media screen and (max-width: 1030px) {
  .aboutUs__right {
    width: 100%;
    padding: 0;
    display: block;
  }
}

.aboutUs__midContent {
  width: auto;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  margin-right: 45px;
  padding-bottom: 55px;
  display: flex;
  position: relative;
}

@media screen and (max-width: 1030px) {
  .aboutUs__midContent {
    margin-right: 0;
    padding-bottom: 0;
  }
}

@media screen and (min-width: 1800px) {
  .aboutUs__midContent {
    margin-right: 130px;
  }
}

.aboutUs__midContent canvas {
  aspect-ratio: 3 / 1;
  z-index: 99999;
  pointer-events: none;
  position: absolute;
  top: -200px;
  left: -150px;
  height: unset !important;
}

.aboutUs__midContent--text {
  width: 38vw;
  z-index: 9999;
  margin-top: 50px;
  position: relative;
}

@media screen and (max-width: 1030px) {
  .aboutUs__midContent--text {
    width: 100%;
    margin-right: 0;
  }
}

.aboutUs__midContent--text .draw__textInner {
  z-index: 1;
  mix-blend-mode: unset;
  white-space: initial;
  width: 100%;
  z-index: 99999;
  margin-left: -62px;
  font-family: Neue World;
  font-size: 32px;
  line-height: 37px;
  position: relative;
}

@media screen and (min-width: 1800px) {
  .aboutUs__midContent--text .draw__textInner {
    margin-left: -75px;
    font-size: 46px;
    line-height: 53px;
  }
}

@media screen and (max-width: 1030px) {
  .aboutUs__midContent--text .draw__textInner {
    width: 100%;
    margin-left: 0;
    font-size: 24px;
    line-height: 28px;
  }
}

.aboutUs__midContent--text .draw__textInner .pt_underline {
  background: none;
}

.aboutUs__midContent--text .draw__textInner .pt_underline a {
  text-align: left;
  color: #fff;
  z-index: 1;
  border: 0;
  font-family: Neue World;
  font-size: 32px;
  line-height: 37px;
}

@media screen and (min-width: 1650px) {
  .aboutUs__midContent--text .draw__textInner .pt_underline a {
    font-size: 46px;
    line-height: 53px;
  }
}

@media screen and (max-width: 1030px) {
  .aboutUs__midContent--text .draw__textInner .pt_underline a {
    font-size: 24px;
    line-height: 28px;
  }
}

.aboutUs__midContent--text .draw__textInner .pt_underline .char {
  display: inline-block;
}

@media screen and (max-width: 1030px) {
  .aboutUs__midContent--text {
    width: 100%;
    margin-top: 35px;
    margin-bottom: 30px;
  }
}

.aboutUs__midContent figure {
  opacity: 0;
  visibility: hidden;
  width: 300px;
  height: 200rem;
  pointer-events: none;
  z-index: -10;
  z-index: 100000;
  transition: opacity .1s, visibility 0s .1s;
  position: absolute;
  top: 50%;
}

@media screen and (max-width: 1030px) {
  .aboutUs__midContent figure {
    display: none;
  }
}

.aboutUs .social {
  display: flex;
}

.aboutUs .social > * {
  margin-right: 30px;
}

.aboutUs__contact {
  width: auto;
  z-index: 1;
  flex-direction: column;
  display: flex;
  position: relative;
}

@media screen and (max-width: 1030px) {
  .aboutUs__contact {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
    display: block;
  }
}

.aboutUs__contact--topText p {
  font-family: Neue World;
  font-size: 32px;
  line-height: 37px;
}

@media screen and (max-width: 1030px) {
  .aboutUs__contact--topText p {
    font-size: 24px;
    line-height: 28px;
  }
}

@media screen and (min-width: 1800px) {
  .aboutUs__contact--topText p {
    font-size: 46px;
    line-height: 53px;
  }
}

.aboutUs__contact--topText p .char {
  display: inline-block;
}

.aboutUs__contact--topText p.formFillText {
  flex-wrap: wrap;
  margin-top: 60px;
  font-size: 20px;
  line-height: 23px;
  display: flex;
}

@media screen and (min-width: 1650px) {
  .aboutUs__contact--topText p.formFillText {
    font-size: 30px;
    line-height: 34px;
  }
}

@media screen and (max-width: 1030px) {
  .aboutUs__contact--topText p.formFillText {
    display: inline-block;
  }
}

.aboutUs__contact--topText p.formFillText a {
  margin-left: 5px;
  font-family: Neue World;
  font-size: 20px;
  line-height: 23px;
  text-decoration: underline;
  position: relative;
}

@media screen and (max-width: 1030px) {
  .aboutUs__contact--topText p.formFillText a {
    text-decoration: underline;
  }
}

@media screen and (min-width: 1650px) {
  .aboutUs__contact--topText p.formFillText a {
    font-size: 30px;
    line-height: 34px;
  }
}

.aboutUs__contact--topText p.formFillText a svg {
  width: 150%;
  height: 28px;
  stroke-dasharray: 280;
  stroke-dashoffset: 280px;
  stroke-width: 1px;
  transition: all .8s ease-out;
  position: absolute;
  top: -4px;
  left: 15px;
  transform: translateX(-50%);
}

@media screen and (min-width: 1650px) {
  .aboutUs__contact--topText p.formFillText a svg {
    width: 57px;
    height: 55px;
    left: 23px;
  }

  .aboutUs__contact--topText p.formFillText a svg rect {
    height: 42px;
    width: 56px;
  }
}

.aboutUs__contact--topText p.formFillText a:hover svg {
  stroke-dasharray: 280;
  stroke-dashoffset: 0;
}

.aboutUs__contact--top {
  width: 664px;
}

@media screen and (max-width: 1030px) {
  .aboutUs__contact--top {
    width: 100%;
    display: block;
  }
}

.aboutUs__contact--top--left {
  max-width: 65%;
  width: 100%;
  padding-bottom: 12px;
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 1030px) {
  .aboutUs__contact--top--left {
    max-width: 100%;
  }
}

.aboutUs__contact--top--left h5 {
  text-transform: capitalize;
  margin-bottom: 20px;
  font-family: GoshaSans;
  font-size: 20px;
  font-weight: normal;
  line-height: 24px;
}

.aboutUs__contact--top--left .contact__text {
  max-width: 259px;
  width: 100%;
  margin-bottom: 10px;
}

.aboutUs__contact--top--left .contact__text.hide, .aboutUs__contact--top--left .show__form.hide {
  opacity: 0;
  visibility: hidden;
}

.aboutUs__contact--top--left .contact__textContent {
  transition: all 1s cubic-bezier(.853, 0, .173, 1);
  transform: translateY(100px);
}

.aboutUs__contact--top--left .contact__textContent.hide {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-100px)scale(.8);
}

.aboutUs__contact--top--left .contact__form {
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 1s cubic-bezier(.853, 0, .173, 1);
  position: relative;
  transform: translateY(100px)scale(.8);
}

.aboutUs__contact--top--left .contact__form.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0)scale(1);
}

.aboutUs__contact--top--left .contact__form--step {
  transition: all 1s cubic-bezier(.853, 0, .173, 1);
}

.aboutUs__contact--top--left .contact__form--step.step2, .aboutUs__contact--top--left .contact__form--step.step3 {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(100px)scale(.8);
}

.aboutUs__contact--top--left .contact__form--step.step2.show, .aboutUs__contact--top--left .contact__form--step.step3.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0)scale(1);
}

.aboutUs__contact--top--left .contact__form--step.step1.completed, .aboutUs__contact--top--left .contact__form--step.step2.completed {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-100px)scale(.8);
}

.aboutUs__contact--top--left .contact__form .contact__message {
  margin-bottom: 10px;
}

.aboutUs__contact--top--left .contact__form .contact__message textarea, .aboutUs__contact--top--left .contact__form .contact__message input {
  width: 100%;
  color: #fff;
  resize: none;
  background: none;
  border: 0;
  outline: 0;
  font-family: GoshaSans;
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  position: relative;
}

@media screen and (max-width: 1030px) {
  .aboutUs__contact--top--left .contact__form .contact__message textarea, .aboutUs__contact--top--left .contact__form .contact__message input {
    padding: 0;
  }
}

.aboutUs__contact--top--left .contact__form .contact__message input {
  margin-bottom: 50px;
}

.aboutUs__contact--top--left .contact__form .send, .aboutUs__contact--top--left .contact__form .form-btn, .aboutUs__contact--top--left .contact__form .close__form {
  color: #fff;
  background: none;
  border: 0;
  outline: 0;
  margin-right: 25px;
  padding: 0;
  font-family: GoshaSans;
  font-size: 12px;
  line-height: 14px;
}

.aboutUs__contact--top--right {
  margin-top: 35px;
}

@media screen and (max-width: 1030px) {
  .aboutUs__contact--top--right {
    margin-top: 30px;
  }
}

.aboutUs__contact--top--right .contact__widget {
  max-width: 133px;
  width: 100%;
  margin-right: 50px;
}

.aboutUs__contact--top--right .contact__widget .title {
  text-transform: uppercase;
}

.aboutUs__contact--top--right .contact__widget:not(:last-child) {
  margin-bottom: 15px;
}

@media screen and (max-width: 1030px) {
  .aboutUs__contact--top--right .contact__widget {
    margin-left: 0;
  }

  .aboutUs__contact--top--right .contact__widget:not(:last-child) {
    margin-right: 20px;
  }
}

.aboutUs__contact--video {
  width: 38vw;
  z-index: 99;
  position: relative;
}

.aboutUs__contact--video .datetime {
  font-family: GoshaSans;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: #fff !important;
}

.aboutUs__contact--video .datetime .one-date {
  width: 30px;
  text-align: center;
  display: inline-block;
}

.aboutUs__contact--video .datetime .one-date.year {
  width: 50px;
  margin-right: 10px;
}

@media screen and (max-width: 1030px) {
  .aboutUs__contact--video {
    width: 100%;
    height: 250px;
    order: 2;
    margin-bottom: 30px;
  }
}

.aboutUs__contact--video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.aboutUs.snGradient svg {
  stroke-dasharray: 280 !important;
  stroke-dashoffset: 0 !important;
}

.aboutUs.snGradient svg rect {
  stroke: #101010;
}

.aboutUs.snGradient p, .aboutUs.snGradient span, .aboutUs.snGradient button, .aboutUs.snGradient a, .aboutUs.snGradient textarea, .aboutUs.snGradient input {
  color: #000 !important;
}

@media screen and (max-width: 1030px) {
  .aboutUs.snGradient {
    background: linear-gradient(#fff0bb 0%, #ffdeee 34.38%, #dfffec 66.67%, #c0ffff 100%), #ad8282;
  }
}

.notFound {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.aboutUs__left {
  z-index: 100;
}

.aboutUs__left--model {
  z-index: 1;
}

.aboutUs__left--content, .aboutUs__left--content > p {
  z-index: -10;
}

.aboutUs__contact--top--right, .aboutUs .contact__info {
  z-index: 100;
}

.splitting .word, .splitting .char {
  display: inline-block;
}

.splitting .char {
  position: relative;
}

.splitting .char:before, .splitting .char:after {
  content: attr(data-char);
  visibility: hidden;
  user-select: none;
  transition: inherit;
  position: absolute;
  top: 0;
  left: 0;
}

.splitting {
  --word-center: calc((var(--word-total)  - 1) / 2);
  --char-center: calc((var(--char-total)  - 1) / 2);
  --line-center: calc((var(--line-total)  - 1) / 2);
}

.splitting .word {
  --word-percent: calc(var(--word-index) / var(--word-total) );
  --line-percent: calc(var(--line-index) / var(--line-total) );
}

.splitting .char {
  --char-percent: calc(var(--char-index) / var(--char-total) );
  --char-offset: calc(var(--char-index)  - var(--char-center) );
  --distance: calc( (var(--char-offset) * var(--char-offset) ) / var(--char-center) );
  --distance-sine: calc(var(--char-offset) / var(--char-center) );
  --distance-percent: calc((var(--distance) / var(--char-center) ) );
}

.splitting.cells img {
  width: 100%;
  display: block;
}

@supports ( display: grid ) {
  .splitting.cells {
    visibility: hidden;
    background-size: cover;
    position: relative;
    overflow: hidden;
  }

  .splitting .cell-grid {
    background: inherit;
    width: 100%;
    height: 100%;
    grid-template: repeat( var(--row-total), 1fr ) / repeat( var(--col-total), 1fr );
    display: grid;
    position: absolute;
    top: 0;
    left: 0;
  }

  .splitting .cell {
    background: inherit;
    position: relative;
    overflow: hidden;
  }

  .splitting .cell-inner {
    background: inherit;
    visibility: visible;
    width: calc(100% * var(--col-total) );
    height: calc(100% * var(--row-total) );
    left: calc(-100% * var(--col-index) );
    top: calc(-100% * var(--row-index) );
    position: absolute;
  }

  .splitting .cell {
    --center-x: calc((var(--col-total)  - 1) / 2);
    --center-y: calc((var(--row-total)  - 1) / 2);
    --offset-x: calc(var(--col-index)  - var(--center-x) );
    --offset-y: calc(var(--row-index)  - var(--center-y) );
    --distance-x: calc( (var(--offset-x) * var(--offset-x) ) / var(--center-x) );
    --distance-y: calc( (var(--offset-y) * var(--offset-y) ) / var(--center-y) );
  }
}

/*# sourceMappingURL=index.8a6842a8.css.map */
