// Import font
@font-face {
  font-family: "GoshaSans";
  src: url("../fonts/GoshaSans-Ultralight.otf");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "GoshaSans";
  src: url("../fonts/GoshaSans-Light.otf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "GoshaSans";
  src: url("../fonts/GoshaSans-Regular.otf");
  font-weight: 400;
  font-style: normal;
}
// @font-face {
//   font-family: "Neue World";
//   src: url("../fonts/NeueWorld-CondensedUltralight.otf");
//   font-weight: 300;
//   font-style: normal;
// }
// @font-face {
//   font-family: "Neue World";
//   src: url("../fonts/NeueWorld-CondensedRegular.otf");
//   font-weight: 400;
//   font-style: normal;
// }
@font-face {
  font-family: "Neue World";
  src: url("../fonts/NeueWorld-SemiCondensedLight.otf");
  font-weight: 500;
  font-style: normal;
}
* {
  font-display: block;
}
// Store font in variables
$fontGosha: "GoshaSans";
$fontNeue: "Neue World";

// Typography

h1.navigation {
  font-family: $fontNeue;
  font-size: 100px;
  line-height: 116px;
  line-height: 1;
  color: #ffffff;
  text-transform: unset;
  font-variation-settings: "wght" 300, "wdth" 85;
  z-index: 1500;
  font-weight: 500;
  @media screen and (max-width: 768px) {
    font-size: 48px;
    line-height: 55px;
  }
  @media screen and (max-width: 350px) {
    font-size: 40px;
    line-height: 50px;
  }
  a {
    display: block;
    width: max-content;
  }
  .new__menuLink {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    // visibility: hidden;

    @media screen and (max-width: 1030px) {
      max-width: calc(100vw - 124px);
      line-height: 38px;
    }
    &.agency__name {
      right: 0;
      left: unset;
    }
    .char {
      opacity: 0;
    }
    &.hide {
      opacity: 0;
    }
  }
  &.zBellow {
    z-index: 0 !important;
    @media screen and (max-width: 1030px) {
      z-index: 1500 !important;
    }
  }
  &.peNone {
    pointer-events: none;
    @media screen and (max-width: 1030px) {
      pointer-events: unset;
    }
  }
}

.footer__link > .navigation > .new__menuLink {
  top: unset;
  bottom: 10px;
}

.footer__link > .navigation > .new__menuLink {
  @media screen and (max-width: 1030px) {
    max-width: calc(100vw - 77px);
  }
}

h1 .char {
  display: inline-block;
  &:not(:first-child) {
    text-transform: unset;
  }
}
h1:hover .char {
  animation-play-state: paused !important;
}
@keyframes breathe {
  0% {
    font-variation-settings: "wght" 300, "wdth" 85;
    font-size: 100px;
  }
  60% {
    font-variation-settings: "wght" 500, "wdth" 100;
    font-size: 120px;
  }

  100% {
    font-variation-settings: "wght" 300, "wdth" 85;
    font-size: 100px;
  }
}

h1.project {
  font-family: $fontGosha;
  font-weight: 200;
  font-size: 220px;
  line-height: 264px;
  color: #ffffff;
  .char {
    text-transform: uppercase !important;
  }
  @media screen and (max-width: 768px) {
    font-size: 80px;
    line-height: 85px;
    text-align: center;
    word-break: break-word;
  }
}

h4.link {
  font-family: $fontGosha;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  text-transform: uppercase;
  width: 90px;
  height: 40px;
  @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 22px;
  }
  a {
    display: block;
  }
  svg {
    position: absolute;
    left: -13px;
    top: -6px;
    stroke-dasharray: 280px;
    stroke-dashoffset: 280px;
    transition: all 0.8s ease-out 0s;
    stroke-width: 1px;
  }
  &:hover {
    svg {
      stroke-dasharray: 280px;
      stroke-dashoffset: 0px;
    }
  }
  a {
    &.active {
      svg {
        stroke-dasharray: 280px !important;
        stroke-dashoffset: 0px !important;
      }
    }
  }
}

.fixed__menuLink {
  .char {
    // transition: all .4s;
    // -webkit-transition: all .4s;
    // -moz-transition: all .4s;
    // &.fadeOut{
    //   transform: translateX(15px) skewY(20.5deg) !important;
    //   transition: all .5s;
    //   -webkit-transition: all .5s;
    //   -moz-transition: all .5s;
    //   opacity: 0;
    // }
    // &.fadeIn{
    //   transform: translateX(0) skewY(0) !important;
    //   transition: all .5s;
    //   -webkit-transition: all .5s;
    //   -moz-transition: all .5s;
    //   opacity: 0;
    // }
  }
}
