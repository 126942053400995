@import "./typography", "./default";

:root {
  --app-height: 100%;
}

* {
  // cursor: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7), none;

  cursor: none !important;
}

html {
  cursor: none !important;
  width: 100vw;
  height: 100%;
  overflow-x: hidden;
  overscroll-behavior-x: none;
}

// Colors variables
$_black: #202020;
$_white: #ffffff;

body {
  background: linear-gradient(180deg, #fff0bb 0%, #ffdeee 34.38%, #dfffec 66.67%, #c0ffff 100%), #121212;
  perspective: 2000px;
  perspective-origin: 50% 0%;
  height: 100%;
  min-height: 100vh;
  cursor: none !important;
  .c-scrollbar_thumb {
    background-color: transparent;

    &::before,
    &::after {
      background-color: transparent;
      position: absolute;
      margin-top: -20px;
      width: 100%;
      height: 20px;
      content: "";
    }

    &::after {
      margin-top: 1px;
    }
  }
  @media screen and (max-width: 1030px) {
    // position: absolute;
    width: 100vw;
    overflow-x: hidden;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    overflow-y: scroll;
    perspective: unset;
    perspective-origin: unset;
    // overscroll-behavior-y: none;
    height: unset;
  }
  &.dark {
    background: $_black;
    .c-scrollbar_thumb {
      background-color: #ffffff;
      opacity: 0.4 !important;
      transition: background-color 0.3s;
      &:hover {
        opacity: 0.8 !important;
      }
    }
  }
  .c-scrollbar_thumb {
    transition: background-color 0.3s;
    background-color: #ffffff;
    opacity: 0.4 !important;
    &:hover {
      opacity: 0.8 !important;
    }
  }
}

.smooth-scroll {
  min-height: 100vh;
  @media screen and (max-width: 1030px) {
    width: 100vw;
  }
}

.fullscreen_mode {
  .video_overlay,
  .workDetails__videoBanner *,
  .vp-center {
    cursor: default !important;
  }
}
.altfullscreen_mode {
  .video__wrapper *,
  vp-center {
    cursor: default !important;
  }
}
.black {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #101010;
  z-index: 16000;
}
.introWrapper {
  position: relative;
  width: 100vw;
  overflow: hidden;
  background: $_black;
  &.siteIn {
    transform: translate3d(0, 0, 0) !important;
  }
  @media screen and (max-width: 1030px) {
    // position: absolute;
    height: 100%;
    // overflow-y: scroll;
    transform: none !important;
  }
}
.introWrapper__mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  // background: #101010;
  transform: translate3d(0.1%, 0px, 0px);

  background: linear-gradient(180deg, #fff0bb 0%, #ffdeee 34.38%, #dfffec 66.67%, #c0ffff 100%);
  z-index: 200000;
  &::before {
    position: absolute;
    content: "";
    width: 20px;
    height: 100%;
  }
  &--mobileLoader {
    display: none;
  }
  @media screen and (max-width: 1030px) {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate3d(0px, 0px, 0px);
    transform-origin: bottom;
    height: 100vh;
    &--mobileLoader {
      display: block;
      svg {
        max-width: 70px;
      }
    }
  }
}
.loadingScreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: $_black;
  z-index: 1400;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  &__content {
    &--anim {
      max-width: 100px;
    }
  }
  @media screen and (max-width: 1030px) {
    position: fixed;
  }
}
.sreenSaver {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $_black;
  z-index: 1200;
  // display: none;
  canvas {
    background: $_black;
  }
}
.mobileLoader {
  display: none;
  @media screen and (max-width: 1030px) {
    position: fixed;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    width: 60px;
    height: 60px;
    z-index: 9000;
    // margin-top: -60px;
  }
}
.custom__Cursor {
  position: fixed;
  top: -25px;
  left: -25px;
  display: flex;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  &.cursor2 {
    display: none;
    &.show {
      display: block;
    }
  }
  svg {
    max-width: 50px;
  }
  @media screen and (max-width: 1030px) {
    display: none;
  }

  &--trail {
    position: fixed;
    display: none;
    opacity: 0;
    color: white;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1;
    transition: all 0.005s;

    font-size: 10px;
    letter-spacing: 0.05em;
    font-family: "GoshaSans";

    & > * {
      &:first-of-type {
        margin-left: 25px;
      }

      display: inline-block;
      will-change: transform;
    }
  }
}

// Video controls
.video_sound {
  display: none;
  background-image: url(../images/sound-white.svg);
  background-position: 0 50%;
  background-repeat: no-repeat;
  padding-left: 20px;
  height: 16px;
  width: 70px;
  position: absolute;
  left: 200px;
  bottom: 40px;
  z-index: 5;
  background-size: contain;
  @media screen and (max-width: 1030px) {
    left: 20px;
    bottom: 15px;
    display: none !important;
  }
}
.video_sound.muted {
  background-image: url(../images/sound-mute-white.svg);
}
.video_sound_line {
  position: relative;
  margin: 7px 0;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  width: 100%;
}
.video_sound_control {
  height: 100%;
  width: 50%;
  background: #fff;
}
.video_overlay,
.alt1_overlay,
.alt2_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  .videoState__text,
  .alt1videoState__text,
  .alt2videoState__text {
    font-family: $fontGosha;
    font-style: normal;
    font-weight: 200;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    text-transform: uppercase;
    color: #ffffff;
    @media screen and (max-width: 1030px) {
      opacity: 1;
      visibility: visible;
    }
  }
  .videoState__text {
    font-size: 160px;
    line-height: 192px;
    &.vedioMode {
      opacity: 0 !important;
    }
    @media screen and (max-width: 1030px) {
      font-size: 40px;
      line-height: 50px;
    }
  }
  .alt1videoState__text,
  .alt2videoState__text {
    font-size: 60px;
    line-height: 72px;
    @media screen and (max-width: 1030px) {
      font-size: 40px;
      line-height: 50px;
    }
  }
  &:hover {
    .videoState__text,
    .alt1videoState__text,
    .alt2videoState__text {
      opacity: 1;
      visibility: visible;
    }
  }
}
.audio-progress-wrap {
  height: 41px;
  position: absolute;
  bottom: 20px;
  display: none;
  z-index: 10;
  width: 360px;
  margin-left: -180px;
  left: 50%;
  padding: 30px;
  @media screen and (max-width: 1030px) {
    bottom: 0;
    padding: 20px;
    display: none !important;
  }
  .audio-progress {
    width: 100%;
    position: relative;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.58);
    .draggable-point {
      display: flex;
      top: 0 !important;
      align-items: center;
      position: absolute;
      z-index: 1;
      margin-top: -5px;
      margin-left: -5px;
      width: 10px;
      height: 10px;
      border: 0;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      background-color: #fff;
      cursor: none;
      padding: 0;
      font-size: 12px;
      font-weight: 900;
      color: #000;
      justify-content: center;
      opacity: 0;
      -webkit-transition: opacity 0.4s ease;
      -moz-transition: opacity 0.4s ease;
      -ms-transition: opacity 0.4s ease;
      -o-transition: opacity 0.4s ease;
      transition: opacity 0.4s ease;
    }
    .bar {
      height: 100%;
      background-color: #fff;
    }
  }
  &:hover {
    .draggable-point {
      opacity: 1;
    }
  }
}
.full_screen_btn,
.full_screen_btn2,
.full_screen_btn3 {
  font-family: $fontGosha;
  position: absolute;
  right: 180px;
  bottom: 40px;
  font-size: 12px;
  font-weight: 400;
  text-decoration: underline;
  background: 0 0;
  border: 0;
  color: #fff;
  z-index: 5;
  display: none;
  @media screen and (max-width: 1030px) {
    right: 20px;
    bottom: 15px;
    display: none !important;
  }
}
.video_started:hover .video_sound,
.video_started:hover .full_screen_btn,
.video_started:hover .full_screen_btn2,
.video_started:hover .full_screen_btn3,
.video_started:hover .audio-progress-wrap {
  display: block;
}

.alt1,
.alt2 {
  .full_screen_btn2 {
    bottom: 15px;
    right: 15px;
  }
  .full_screen_btn3 {
    bottom: 15px;
    right: 15px;
  }
}
// html.has-scroll-smooth {
//   overflow: auto !important;
// }
main {
  position: relative;
  z-index: 1;
  @media screen and (max-width: 1030px) {
    // position: absolute;
    // height: 100%;
    // overflow: scroll;
    // top: 0;
    // transform: none !important;
  }
}
.container {
  @media screen and (max-width: 1030px) {
    background: $_black;
  }
}
.dsMenu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  @media screen and (max-width: 1030px) {
    display: none;
  }
  .tl {
    position: absolute;
    top: 3vw;
    left: 3vw;
    z-index: 1500;
  }
  .br {
    position: absolute;
    z-index: 99;
    bottom: 3vw;
    right: 50px;
    width: max-content;
    z-index: 1500;
  }
  .tr {
    position: absolute;
    top: 3vw;
    left: calc(100vw - 115px);
    z-index: 1500;
  }
  .bl {
    position: absolute;
    left: 3.5vw;
    bottom: 3vw;
    z-index: 1500;
  }
  &.snGradient {
    background: linear-gradient(180deg, #fff0bb 0%, #ffdeee 34.38%, #dfffec 66.67%, #c0ffff 100%), #ad8282;
    transition: background all 0.6s;
    -webkit-transition: background 0.6s;
    -moz-transition: background 0.6s;
    h1,
    a {
      color: $_black;
      transition: all 0.3s;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
    }
    h4 {
      svg {
        rect {
          stroke: $_black;
        }
      }
    }
  }
}
.header {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1500;
  padding: 30px 20px 40px;
  -webkit-backface-visibility: hidden;
  background: linear-gradient(0deg, rgba(32, 32, 32, 0) 0%, #202020 67.55%);
  @media screen and (max-width: 1030px) {
    display: block;
  }
  &__wrapper {
    position: relative;
    align-items: flex-start;

    @media screen and (max-width: 1030px) {
      top: 3vw;
    }
  }
  &.zBellow {
    z-index: 0;
  }
  &.snGradient {
    background: transparent;
    h1,
    h4 {
      color: $_black;
    }
    h4 {
      svg {
        rect {
          stroke: $_black;
        }
      }
    }
  }
  @media screen and (max-width: 1030px) {
    h4 {
      text-align: right;
      svg {
        left: 18px;
        top: -8px;
      }
    }
  }
  .tl {
    position: relative;
    z-index: 1500;
  }
  .br {
    position: relative;
    z-index: 1500;

    @media screen and (max-width: 350px) {
      margin-bottom: -2px;
    }
  }
  .tr {
    position: relative;
    z-index: 1500;
  }
  .bl {
    position: relative;
    z-index: 1500;
  }
}
.footer {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  z-index: 1500;
  padding: 40px 20px 20px;
  -webkit-backface-visibility: hidden;
  background: linear-gradient(180deg, rgba(32, 32, 32, 0) 0%, #202020 72.09%);
  &.snGradient {
    background: transparent;
    h1,
    h4 {
      color: $_black;
    }
    h4 {
      svg {
        rect {
          stroke: $_black;
        }
      }
    }
  }
  @media screen and (max-width: 1030px) {
    display: block;
  }
  &.zBellow {
    z-index: 0;
  }

  &__wrapper {
    align-items: flex-end;
    position: relative;
  }
  .br {
    // position: relative;
    z-index: 1500;

    @media screen and (max-width: 1030px) {
      text-align: right;
      bottom: 35px;
    }
    @media screen and (max-width: 768px) {
      transform: translateY(6px);
    }
  }
  .bl {
    position: relative;
    z-index: 1500;
    display: flex;
    align-items: center;
  }
  @media screen and (max-width: 1030px) {
    h4 {
      text-align: left;
      svg {
        left: -16px;
        top: 0px;
      }
    }
  }
}
.pageContent {
  @media screen and (max-width: 1030px) {
    flex-direction: column;
  }
  &__menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: $_black;
    &.snGradient {
      background: linear-gradient(180deg, #fff0bb 0%, #ffdeee 34.38%, #dfffec 66.67%, #c0ffff 100%), #ad8282;
      transition: background all 0.6s;
      -webkit-transition: background 0.6s;
      -moz-transition: background 0.6s;
      h1,
      a {
        color: $_black;
        transition: all 0.3s;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
      }
    }

    @media screen and (max-width: 1030px) {
      top: 0;
      bottom: 0;
      width: 0;
      height: 0;
      position: sticky;
      position: -webkit-sticky;
      z-index: 9000;
      // transform: translateY(-50%);
    }
  }
  &__projects {
    position: relative;
    padding-right: 200px;
    padding-left: 350px;
    @media screen and (max-width: 1030px) {
      padding: 0;
      flex-direction: column;
      width: 100%;
      // overflow-x: hidden;
    }
    &--mobileAnim {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: -15%;
      display: none;
      z-index: -1;
      // overflow: hidden;
      @media screen and (max-width: 1030px) {
        display: block;
        svg {
          width: 120% !important;
          height: 120% !important;
        }
      }
    }
    .canvasDraw {
      position: absolute;
      width: 100vw;
      height: 100vh;
      display: block;
    }
    canvas {
      display: block;
      // width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      pointer-events: none;
    }
  }
  &__projectWrapper {
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0;
    align-items: center;
    height: 100vh;
    z-index: 999;
    @media screen and (max-width: 1030px) {
      width: 100%;
      justify-content: center;
      height: auto;
      min-height: 70vh;
      // min-height: -webkit-fill-available;
    }
    &:first-child {
      @media screen and (max-width: 1030px) {
        min-height: 100vh;
      }
    }
    .projectsListItem {
      &__inner {
        padding: 0 120px;
        @media screen and (max-width: 1030px) {
          padding: 0 20px;
        }
      }
      figure {
        position: absolute;
        opacity: 0;
        top: 50%;
        visibility: hidden;
        width: 848px;
        width: 53rem;
        height: 1152px;
        height: 72rem;
        pointer-events: none;
        z-index: -10;
        -webkit-transition: opacity 0.1s, visibility 0s 0.1s;
        transition: opacity 0.1s, visibility 0s 0.1s;
        z-index: 100000;
        @media screen and (max-width: 1030px) {
          display: none;
        }
      }
      a {
        display: block;
      }
    }
    &:last-child {
      padding-right: 0;
      .projectsListItem {
        padding-right: 0;
      }
    }
  }
  &__homeFooter {
    position: relative;
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    .video__wrapper {
      position: relative;
      .intro_us_lisk {
        pointer-events: none;
        position: relative;
        display: block;
        z-index: 1;
        &::before {
          position: absolute;
          content: "";
          width: 100%;
          height: 100%;
          background: radial-gradient(circle closest-side at 50% 50%, rgba(32, 32, 32, 0) 50%, #202020);
          z-index: 11;
        }
      }
    }
    video {
      position: relative;
      // max-width: 800px;
      height: 100vh;
      width: 100%;
      z-index: 1;
      transform: rotate(0.01deg);
    }
    &--anim {
      display: flex;
      width: 100vw;
      height: 100vh;
      justify-content: center;
      align-items: center;
      // margin-left: -140px;
      @media screen and (max-width: 1030px) {
        width: 100%;
        margin-left: 0;
      }
    }
    svg,
    img {
      max-width: 800px;
    }
  }
}

.workPreloader,
.projectPreloader,
.notFoundPreloader,
.usPreloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999990;
  background: #181818;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  &.hide {
    opacity: 0;
    visibility: hidden;
  }
  .preloadAnim,
  .preloadAnim2 {
    svg {
      max-width: 65px;
    }
  }
  @media screen and (max-width: 1030px) {
    height: 100vh;
  }
}
// Work page style
.work {
  position: relative;
  padding: 230px 50px 185px 50px;
  display: flex;
  min-width: 100vw;
  height: 100vh;
  align-items: center;
  @media screen and (max-width: 1030px) {
    padding: 120px 20px 100px 20px;
    display: block;
    height: auto;
    background: $_black;
  }
  &__wrapper {
    width: 100%;
    height: 100%;
    align-items: center;
    @media screen and (max-width: 1030px) {
      display: block;
      height: auto;
    }
    &--introText,
    &--insText {
      position: absolute;
      p {
        font-family: $fontNeue;
        font-weight: normal;
        font-size: 30px;
        line-height: 36px;
        color: $_white;
        white-space: normal;
        @media screen and (max-height: 730px) {
          font-size: 24px;
          line-height: 28px;
        }
        @media screen and (max-width: 1030px) {
          font-size: 24px;
          line-height: 28px;
        }
        @media screen and (min-width: 1800px) {
          font-size: 46px;
          line-height: 53px;
        }
        span {
          display: inline-block;
          &.word {
            &:not(:last-child) {
              margin-right: 6px;
            }
          }
        }
      }
    }
    &--introText {
      position: absolute;
      top: -75px;
      left: 0;
      @media screen and (max-width: 1030px) {
        position: relative;
        margin-bottom: 20px;
        top: unset;
        left: unset;
        width: 100%;
        grid-column: span 12;
      }
      @media screen and (min-width: 1800px) {
        top: -110px;
      }
    }
    &--insText {
      left: calc(100vw - 795px);
      bottom: -100px;
      text-align: right;
      @media screen and (max-width: 1030px) {
        position: relative;
        margin-bottom: 40px;
        bottom: unset !important;
        left: unset !important;
        text-align: left;
        width: 100%;
        grid-column: span 12;
      }
      p {
        max-width: 690px;
        width: 100%;
      }
      @media screen and (max-height: 730px) {
        left: calc(100vw - 660px);
        bottom: -60px;
        p {
          max-width: 560px;
        }
      }
      @media screen and (min-width: 1800px) {
        left: calc(100vw - 1120px);
        bottom: -135px;
        p {
          max-width: 1020px;
          width: 100%;
        }
      }
    }
  }
  &__list {
    position: relative;
    list-style: none;
    padding: 0;
    width: 100%;
    overflow: visible;
    @media screen and (max-width: 1030px) {
      display: grid;
      grid-column-gap: 10px;
      grid-row-gap: 20px;
      grid-template-columns: repeat(12, 1fr);
    }
    &--item {
      position: relative;
      display: block;
      width: 100%;
      margin-bottom: 15px;
      width: 250px;
      &--mask {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        transition: all 0s linear;
        transition-delay: 0.5s;
        @media screen and (max-width: 1030px) {
          display: none;
        }
      }
      @media screen and (max-width: 1030px) {
        width: 100%;
        grid-column: span 6;
      }
      @media screen and (min-width: 1800px) {
        width: 280px;
      }
      &:not(:last-child) {
        margin-right: 12px;
      }
      .item__video {
        position: relative;
        width: 100%;
        height: 150px;
        background: #262626;
        @media screen and (max-width: 1030px) {
          display: none;
        }
        @media screen and (min-width: 1800px) {
          height: 160px;
        }
        video {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          -o-object-fit: cover;
          object-fit: cover;
          -webkit-transform: rotate(0.001deg);
          transform: rotate(0.001deg);
        }
      }
      .item__mobileThumb {
        width: 100%;
        height: 110px;
        background: transparent;
        display: none;
        @media screen and (max-width: 1030px) {
          display: block;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .item__title {
        position: relative;
        margin-top: 6px;
        width: max-content;
        span {
          font-family: $fontGosha;
          font-style: normal;
          font-weight: 300;
          font-size: 11px;
          line-height: 13px;
          text-transform: capitalize;
          color: rgba(255, 255, 255, 1);
          mix-blend-mode: difference;
          opacity: 0.2;
          transition: all 0.3s;
          @media screen and (max-width: 1030px) {
            opacity: 1;
          }
          // text-transform: uppercase;
        }
        svg {
          position: absolute;
          left: 66%;
          top: -4px;
          width: 180%;
          height: 28px;
          stroke-dasharray: 280px;
          stroke-dashoffset: 280px;
          transition: all 0.8s ease-out 0s;
          stroke-width: 1px;
          transform: translateX(-50%);
          &.active {
            stroke-dasharray: 280px;
            stroke-dashoffset: 0px;
            rect {
              width: 88%;
              height: 26px;
            }
          }
          @media screen and (max-width: 1030px) {
            display: none;
          }
        }
      }
      &:hover {
        .item__title {
          span {
            opacity: 1;
          }
        }
        .work__list--item--mask {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }
}

// Work details page style
.workDetails {
  position: relative;
  padding: 95px 60px 95px 130px;
  width: max-content;
  height: 100vh;
  align-items: center;
  @media screen and (max-width: 1030px) {
    width: 100vw;
    padding: 120px 20px;
    display: block;
    height: auto;
  }
  &__wrapper {
    width: max-content;
    @media screen and (max-width: 1030px) {
      width: 100%;
      display: block;
    }
  }
  &__backbtn {
    margin-top: 20px;
    display: none;
    @media screen and (max-width: 1030px) {
      display: block;
    }
    a {
      font-family: $fontGosha;
      font-weight: normal;
      font-size: 10px;
      line-height: 10px;
      color: #fff;
      font-weight: 400;
    }
  }
  &__videoBanner {
    position: relative;
    width: calc(100vw - 260px);
    z-index: 99;
    @media screen and (max-width: 1030px) {
      width: 100%;
      transform: none !important;
    }
    .videoInner {
      position: relative;
      display: block;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      z-index: 9999;
      top: 50%;
      margin-bottom: auto;
      // padding-bottom: 40.55%;
      height: calc(100vh - 170px);
      transform: translateY(-50%);
      @media screen and (max-width: 1030px) {
        top: 0;
        transform: none !important;
        height: auto;
        padding-top: 56.25%;
      }
      &__controls {
        position: relative;
        width: 100%;
        max-height: 700px;
        min-height: 585px;
        height: 100%;
        &.vedioMode {
          opacity: 0;
        }
        @media screen and (min-height: 900px) and(min-width:1600) {
          max-height: 800px;
        }
        @media screen and (min-height: 1000px) and (min-width: 1800px) {
          max-height: 900px;
        }
        @media screen and (min-height: 1200px) and (min-width: 1900px) {
          max-height: 1100px;
        }
        @media screen and (min-height: 1400px) and (min-width: 2000px) {
          max-height: 1300px;
        }
        @media screen and (max-height: 750px) {
          max-height: 550px;
        }
        @media screen and (max-height: 750px) {
          min-height: auto;
          max-height: 550px;
        }
        @media screen and (max-width: 1030px) {
          min-height: auto;
        }
        @media screen and (max-width: 575px) {
          min-height: auto !important;
        }
      }
    }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  &--more {
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1030px) {
      display: none;
    }

    &__btn {
      font-family: $fontGosha;
      font-size: 12px;
      line-height: 14px;
      color: white;
      text-transform: uppercase;
      padding: 20px;
      opacity: 0;
      pointer-events: none;
      transition: all 0.5s;
      background-color: transparent;
      border: none;
      outline: none;
      transform: translateX(-20px) rotate(90deg);

      &.show {
        transform: translateX(0) rotate(90deg);
        opacity: 1;
        pointer-events: all;
      }
    }
  }
  &__workInfo {
    // max-height: 870px;
    // min-height: 585px;
    // height: 100%;
    // justify-content: flex-end;
    justify-content: space-between;
    gap: 80px;
    width: max-content;
    color: $_white;
    margin-left: 0;
    // margin-top: auto;
    // margin-bottom: auto;
    margin-top: 10vh;
    margin-bottom: 10vh;
    flex-direction: column;

    &.break {
      &--1 {
        flex-direction: row;

        & .workBio {
          margin-top: -1.1px;
        }
      }
      &--2 {
        margin-top: 20vh;

        & .workDetails__workInfo--bottom {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;

          & .workBio {
            height: 100%;
            flex-shrink: 0;
            margin-right: 80px;
            margin-top: -1.1px;
          }

          & .altInfo {
            margin-bottom: 15px;

            & .title {
              margin-top: 0;
            }
          }
        }
      }
    }
    &--top {
      @media screen and (min-width: 1200px) {
        // margin-bottom: auto;
      }
    }
    @media screen and (max-height: 1400px) {
      // min-height: 520px;
    }
    @media screen and (max-width: 1400px) {
      margin-left: 80px;
    }
    @media screen and (min-height: 800px) {
      margin-left: 80px;
      flex-direction: column;
      gap: 40px;
    }
    @media screen and (max-width: 1030px) {
      margin-left: 0;
      margin-top: 60px;
      min-height: auto;
    }
    @media screen and (max-width: 575px) {
      min-height: auto !important;
    }
    span,
    p {
      font-family: $fontGosha;
      font-weight: 400;
    }
    &--top {
      .clientInfo,
      .agencyInfo {
        opacity: 0;
        transform: translateX(15px);
        transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        -webkit-transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        -moz-transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        &.show {
          opacity: 1;
          transform: translateX(0);
        }
        &__title {
          text-transform: uppercase;
        }
        span {
          font-size: 12px;
          line-height: 14px;
        }
        @media screen and (max-width: 1030px) {
          opacity: 1;
          transform: translateY(0);
        }
      }
      .agencyInfo {
        margin-top: 20px;
      }
    }
    &--bottom {
      opacity: 0;
      transform: translateX(20px);
      transition: all 0.5s;
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      @media screen and (max-width: 1030px) {
        margin-top: 30px;
        opacity: 1;
        transform: translateY(0);
        margin-bottom: 0;
        padding-bottom: 0;
      }
      &.show {
        opacity: 1;
        transform: translateX(0);
      }
      .workBio {
        @media screen and (max-width: 1030px) {
          // margin-bottom: 100px;
        }
        &__title {
          span {
            font-family: $fontGosha;
            font-weight: normal;
            font-size: 22px;
            line-height: 1;
            text-transform: capitalize;
            color: $_white;
          }
          margin-bottom: 18px;
        }
        &__text {
          max-width: 235px;
          width: 100%;
          p {
            white-space: normal;
            font-size: 12px;
            line-height: 14px;
            text-transform: capitalize;
            color: $_white;
          }
          pre {
            font-size: 12px;
            line-height: 14px;
            text-transform: capitalize;
            color: $_white;
            font-family: "GoshaSans";
            font-weight: 400;
          }
          margin-bottom: 30px;
        }
      }
      .altInfo {
        display: none;
        .title {
          margin-top: 15px;
        }
        span {
          font-size: 12px;
          line-height: 14px;
          text-transform: capitalize;
          color: $_white;
        }
        &.show {
          display: block;
        }
      }
      // &.split_more {
      //   padding-bottom: 60px;
      //   .workBio__text {
      //     max-width: 550px;
      //     p {
      //       &:last-child {
      //         display: inline-block;
      //         float: right;
      //         margin-top: -20.5%;
      //       }
      //     }
      //   }
      // }
    }
  }
  &__altBanner {
    position: relative;
    display: flex;
    // max-height: 870px;
    min-height: 585px;
    height: 94%;
    margin-left: 200px;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 99;
    display: none;
    margin-top: auto;
    margin-bottom: auto;
    &.showAlt {
      display: flex;
    }
    .alt2 {
      margin-top: 30px;
    }
    @media screen and (max-height: 1400px) {
      min-height: 520px;
    }
    @media screen and (max-width: 1030px) {
      margin-left: 0;
      margin-top: 30px;
      .alt2 {
        margin-top: 30px;
      }
    }
    @media screen and (max-width: 575px) {
      min-height: auto !important;
      height: 100%;
    }
    .singleBanner {
      position: relative;
      width: 364px;
      padding-bottom: 56.96%;
      opacity: 0;
      transform: translateX(20px);
      transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      -webkit-transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      -moz-transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      &:not(:first-child) {
        margin-top: 35px;
      }
      &.fullscreenon {
        padding: 0;
        transition: none;
        opacity: 1;
      }
      @media screen and (max-width: 1030px) {
        width: 100%;
        opacity: 1;
        transform: none;
        margin-top: 35px;
      }
      &.show {
        opacity: 1;
        transform: translateX(0);
      }
      &__video {
        &.vedioMode {
          *:not(iframe) {
            opacity: 0;
          }
        }
      }
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
      &:not(:last-child) {
        margin-bottom: 35px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

// About us page style
.aboutUs {
  position: relative;
  padding: 95px 55px 95px 50px;
  min-width: 100vw;
  height: 100vh;
  color: $_white;
  @media screen and (max-width: 1030px) {
    padding: 50px 0;
    padding-bottom: 90px;
    display: block;
    height: auto;
  }
  &__wrapper {
    position: relative;
    width: 100%;
    @media screen and (max-width: 1030px) {
      padding: 0 20px;
      display: block;
    }
  }
  p,
  a {
    position: relative;
    font-family: $fontGosha;
    font-weight: 400;
    white-space: normal;
    font-size: 14px;
    line-height: 17px;
    mix-blend-mode: difference;
    @media screen and (min-width: 1650px) {
      font-size: 13px;
      line-height: 16px;
    }
  }
  &__left {
    padding-top: 80px;
    padding-bottom: 50px;
    width: auto;
    &--wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    @media screen and (max-width: 1030px) {
      display: block;
      margin-bottom: 0;
      padding-bottom: 0;
      padding-top: 30px;
    }
    &--model {
      position: relative;
      width: 750px;
      height: 100%;
      margin-right: 50px;
      display: flex;
      align-items: flex-end;
      @media screen and (min-width: 1650px) {
        width: 1025px;
      }
      @media screen and (min-width: 2150px) {
        width: 60vw;
      }
      @media screen and (max-width: 1030px) {
        width: 100%;
        margin-right: 0;
        display: block;
        margin-top: 30px;
        margin-bottom: 30px;
        .webgl2 {
          display: none;
        }
      }
      .mobile__model {
        display: none;
        @media screen and (max-width: 1030px) {
          display: block;
          width: 100%;
        }
        video {
          width: 100%;
        }
      }
      .webGl__wrapper {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(-90px) translateX(0);
        canvas {
          position: absolute;
          top: -85px;
          left: -480px;
          // width: 750px !important;
          // height: 670px !important;
          // transform: translateY(-50px) translateX(-100px);
          @media screen and (max-width: 1030px) {
            display: none !important;
          }
          @media screen and (min-width: 1800px) {
            left: -580px;
          }
        }
      }
    }
    &--content {
      position: relative;
      width: 430px;
      z-index: 1;
      @media screen and (min-width: 1800px) {
        width: 530px;
      }
      @media screen and (max-width: 1030px) {
        margin-left: 0;
        margin-top: 50px;
        width: 100%;
      }
      p {
        font-family: $fontNeue;
        font-size: 32px;
        line-height: 37px;
        @media screen and (max-width: 1340px) {
          font-size: 30px;
          line-height: 34px;
          max-width: 360px;
        }
        @media screen and (max-width: 1030px) {
          font-size: 24px;
          line-height: 28px;
        }
        @media screen and (min-width: 1800px) {
          font-size: 46px;
          line-height: 53px;
        }
        .char {
          display: inline-block;
        }
        &.content__text {
          margin-bottom: 35px;
        }
        &.content__serviceTitle {
          text-transform: uppercase;
          margin-bottom: 15px;
        }
      }
    }
  }
  &__right {
    width: 100vw;
    display: flex;
    padding-left: 120px;
    @media screen and (max-width: 1030px) {
      display: block;
      width: 100%;
      padding: 0;
    }
  }
  &__midContent {
    position: relative;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    margin-right: 45px;
    padding-bottom: 55px;
    @media screen and (max-width: 1030px) {
      margin-right: 0;
      padding-bottom: 0;
    }
    @media screen and (min-width: 1800px) {
      margin-right: 130px;
    }
    canvas {
      position: absolute;
      top: -200px;
      aspect-ratio: 3/1;
      left: -150px;
      // width: auto !important;
      height: unset !important;
      z-index: 99999;
      pointer-events: none;
      // transform: translateY(-50%) !important;
      @media screen and (min-width: 1650px) {
        // height: 340%;
      }
    }
    &--text {
      position: relative;
      margin-top: 50px;
      width: 38vw;
      z-index: 9999;
      @media screen and (max-width: 1030px) {
        width: 100%;
        margin-right: 0;
      }
      // @media screen and (min-width: 1650px) {
      //   margin-right: 200px;
      // }
      .draw__textInner {
        position: relative;
        font-family: $fontNeue;
        font-size: 32px;
        line-height: 37px;
        z-index: 1;
        mix-blend-mode: unset;
        white-space: initial;
        width: 100%;
        margin-left: -62px;
        z-index: 99999;
        @media screen and (min-width: 1800px) {
          font-size: 46px;
          line-height: 53px;
          margin-left: -75px;
        }
        @media screen and (max-width: 1030px) {
          width: 100%;
          font-size: 24px;
          line-height: 28px;
          margin-left: 0;
        }
        .draw__text {
        }
        .pt_underline {
          background: transparent;
          a {
            font-family: $fontNeue;
            text-align: left;
            border: 0;
            color: $_white;
            font-size: 32px;
            line-height: 37px;
            z-index: 1;
            @media screen and (min-width: 1650px) {
              font-size: 46px;
              line-height: 53px;
            }
            @media screen and (max-width: 1030px) {
              font-size: 24px;
              line-height: 28px;
            }
          }
          .char {
            display: inline-block;
          }
        }
      }
      @media screen and (max-width: 1030px) {
        margin-bottom: 30px;
        margin-top: 35px;
        width: 100%;
      }
    }
    figure {
      position: absolute;
      opacity: 0;
      top: 50%;
      visibility: hidden;
      width: 300px;
      height: 200rem;
      pointer-events: none;
      z-index: -10;
      -webkit-transition: opacity 0.1s, visibility 0s 0.1s;
      transition: opacity 0.1s, visibility 0s 0.1s;
      z-index: 100000;
      @media screen and (max-width: 1030px) {
        display: none;
      }
    }
  }

  .social {
    display: flex;

    & > * {
      margin-right: 30px;
    }
  }
  &__contact {
    position: relative;
    width: auto;
    display: flex;
    flex-direction: column;
    z-index: 1;
    @media screen and (max-width: 1030px) {
      display: block;
      width: 100%;
      max-width: 100%;
      margin-left: 0;
    }
    &--topText {
      p {
        font-family: $fontNeue;
        font-size: 32px;
        line-height: 37px;
        @media screen and (max-width: 1030px) {
          font-size: 24px;
          line-height: 28px;
        }
        @media screen and (min-width: 1800px) {
          font-size: 46px;
          line-height: 53px;
        }
        .char {
          display: inline-block;
        }
        &.formFillText {
          margin-top: 60px;
          font-size: 20px;
          line-height: 23px;
          display: flex;
          flex-wrap: wrap;
          @media screen and (min-width: 1650px) {
            font-size: 30px;
            line-height: 34px;
          }
          @media screen and (max-width: 1030px) {
            display: inline-block;
          }
          a {
            position: relative;
            font-family: $fontNeue;
            font-size: 20px;
            line-height: 23px;
            margin-left: 5px;
            text-decoration: underline;
            @media screen and (max-width: 1030px) {
              text-decoration: underline;
            }
            @media screen and (min-width: 1650px) {
              font-size: 30px;
              line-height: 34px;
            }
            svg {
              position: absolute;
              left: 15px;
              top: -4px;
              width: 150%;
              height: 28px;
              stroke-dasharray: 280px;
              stroke-dashoffset: 280px;
              transition: all 0.8s ease-out 0s;
              stroke-width: 1px;
              transform: translateX(-50%);
              @media screen and (min-width: 1650px) {
                width: 57px;
                height: 55px;
                left: 23px;
                rect {
                  height: 42px;
                  width: 56px;
                }
              }
            }
            &:hover {
              svg {
                stroke-dasharray: 280px;
                stroke-dashoffset: 0px;
              }
            }
          }
        }
      }
    }
    &--top {
      width: 664px;
      @media screen and (max-width: 1030px) {
        display: block;
        width: 100%;
      }
      &--left {
        position: relative;
        max-width: 65%;
        width: 100%;
        overflow: hidden;
        padding-bottom: 12px;
        @media screen and (max-width: 1030px) {
          max-width: 100%;
        }
        h5 {
          font-family: $fontGosha;
          font-weight: normal;
          font-size: 20px;
          line-height: 24px;
          text-transform: capitalize;
          margin-bottom: 20px;
        }
        .contact__text {
          max-width: 259px;
          width: 100%;
          margin-bottom: 10px;
          &.hide {
            opacity: 0;
            visibility: hidden;
          }
        }
        .show__form {
          &.hide {
            opacity: 0;
            visibility: hidden;
          }
        }
        .contact__textContent {
          transition: all 1s cubic-bezier(0.853, 0, 0.173, 1);
          -webkit-transition: all 1s cubic-bezier(0.853, 0, 0.173, 1);
          -moz-transition: all 1s cubic-bezier(0.853, 0, 0.173, 1);
          transform: translateY(100px);
          &.hide {
            transform: translateY(-100px) scale(0.8);
            opacity: 0;
            visibility: hidden;
          }
        }
        .contact__form {
          position: relative;
          width: 100%;
          transform: translateY(100px) scale(0.8);
          opacity: 0;
          visibility: hidden;
          transition: all 1s cubic-bezier(0.853, 0, 0.173, 1);
          -webkit-transition: all 1s cubic-bezier(0.853, 0, 0.173, 1);
          -moz-transition: all 1s cubic-bezier(0.853, 0, 0.173, 1);
          &.show {
            transform: translateY(0) scale(1);
            opacity: 1;
            visibility: visible;
          }
          &--step {
            transition: all 1s cubic-bezier(0.853, 0, 0.173, 1);
            -webkit-transition: all 1s cubic-bezier(0.853, 0, 0.173, 1);
            -moz-transition: all 1s cubic-bezier(0.853, 0, 0.173, 1);
            &.step2,
            &.step3 {
              top: 0;
              left: 0;
              position: absolute;
              transform: translateY(100px) scale(0.8);
              opacity: 0;
              &.show {
                transform: translateY(0) scale(1);
                opacity: 1;
                visibility: visible;
              }
            }
            &.step1,
            &.step2 {
              &.completed {
                transform: translateY(-100px) scale(0.8);
                opacity: 0;
                visibility: hidden;
              }
            }
          }
          .contact__message {
            margin-bottom: 10px;
            textarea,
            input {
              font-family: $fontGosha;
              position: relative;
              width: 100%;
              background: transparent;
              font-size: 20px;
              line-height: 24px;
              font-weight: 300;
              color: #ffffff;
              border: 0;
              outline: 0;
              resize: none;
              @media screen and (max-width: 1030px) {
                padding: 0;
              }
            }
            input {
              margin-bottom: 50px;
            }
          }
          .send,
          .form-btn,
          .close__form {
            font-family: $fontGosha;
            background: transparent;
            border: 0;
            outline: 0;
            font-size: 12px;
            line-height: 14px;
            padding: 0;
            color: #ffffff;
            margin-right: 25px;
          }
        }
      }
      &--right {
        margin-top: 35px;
        @media screen and (max-width: 1030px) {
          margin-top: 30px;
        }
        .contact__widget {
          max-width: 133px;
          width: 100%;
          margin-right: 50px;
          .title {
            text-transform: uppercase;
          }
          &:not(:last-child) {
            margin-bottom: 15px;
          }
          @media screen and (max-width: 1030px) {
            margin-left: 0;
            &:not(:last-child) {
              margin-right: 20px;
            }
          }
        }
      }
    }
    &--video {
      position: relative;
      width: 38vw;
      z-index: 99;
      // @media screen and (min-width: 1650px) {
      //   width: 809px;
      //   height: 468px;
      // }
      .datetime {
        font-family: $fontGosha;
        display: flex;
        position: absolute;
        bottom: 20px;
        left: 20px;
        font-size: 12px;
        line-height: 14px;
        color: $_white !important;
        .one-date {
          width: 30px;
          text-align: center;
          display: inline-block;
          &.year {
            width: 50px;
            margin-right: 10px;
          }
        }
      }
      @media screen and (max-width: 1030px) {
        width: 100%;
        height: 250px;
        order: 2;
        margin-bottom: 30px;
      }
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &.snGradient {
    svg {
      stroke-dasharray: 280px !important;
      stroke-dashoffset: 0px !important;
      rect {
        stroke: #101010;
      }
    }
    p,
    span,
    button,
    a,
    textarea,
    input {
      color: #000000 !important;
    }
    @media screen and (max-width: 1030px) {
      background: linear-gradient(180deg, #fff0bb 0%, #ffdeee 34.38%, #dfffec 66.67%, #c0ffff 100%), #ad8282;
    }
  }
}

.notFound {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.aboutUs {
  &__left {
    z-index: 100;

    &--model {
      z-index: 1;
    }

    &--content {
      z-index: -10;

      & > p {
        z-index: -10;
      }
    }
  }

  &__contact--top--right,
  .contact__info {
    z-index: 100;
  }
}
